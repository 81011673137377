<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card
            color="success"
            icon="mdi-apps"
            :title="baseMaterialCardTitle"
            inline
        >
          <v-row>
            <v-col cols="10"></v-col>
            <v-col cols="2" class="text-right">
              <v-btn small right color="info" class="mr-0" @click="$router.push(backRoute)">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" md="12">
              <template v-if="!tenant">
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>
              <template v-else>
                <v-tabs right>
                  <v-tab v-if="!user && !account">
                    <v-icon class="mr-1">mdi-information</v-icon>
                    General
                  </v-tab>
                  <v-tab v-if="!tenant.login">
                    <v-icon class="mr-1">mdi-cog</v-icon>
                    Configuration
                  </v-tab>

                  <v-tab-item class="mt-3" v-if="!user && !account">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            label="Name *"
                            :rules="[() => !!tenant.name || 'This field is required!']"
                            v-model="tenant.name"
                            counter
                            required
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                            label="Description "
                            v-model="tenant.description"
                            counter
                            required
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-select
                            label="Caching TTL *"
                            v-model="tenant._TTL"
                            :rules="[() => !!tenant._TTL || 'This field is required!']"
                            required
                            item-text="text"
                            item-value="value"
                            :items="msCacheOptions"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item class="mt-3" v-if="!tenant.login">
                    <v-row>
                      <v-col :cols="(isTenant) ? 6 : 12">
                        <v-expansion-panels focusable>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <template v-slot:actions>
                                <v-icon color="success">
                                  mdi-cog
                                </v-icon>
                              </template>
                              <h3>Common Configuration</h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row v-if="!isTenant">
                                <v-col class="text-right" cols="12">

                                  <v-btn
                                      class="mt-2 mr-2" small
                                      color="success"
                                      @click="preview('common')"
                                  >
                                    <v-icon class="mr-1">mdi-eye-refresh-outline</v-icon>
                                    Preview
                                  </v-btn>

                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col :cols="(isTenant) ? 12 : 4">
                                  <h4 v-if="!isTenant">
                                    {{ col1stTitle }}
                                  </h4>

                                  <v-jsoneditor
                                      class="py-2"
                                      v-model="tenant.config.common"
                                      :plus="true" :options="{mode: 'code'}"
                                      height="400px"
                                  />
                                </v-col>
                                <v-col cols="4" v-if="!isTenant">
                                  <h4>
                                    {{ col2ndTitle }}
                                  </h4>

                                  <json-viewer
                                      v-if="originConf.config && originConf.config.common"
                                      class="my-2 font11"
                                      :value="originConf.config.common"
                                      :expand-depth="2"
                                      theme="my-awesome-json-theme"
                                      boxed
                                      expanded
                                      height="400px"
                                      :copyable="true"
                                  ></json-viewer>

                                </v-col>
                                <v-col cols="4" v-if="!isTenant">
                                  <h4> Aggregated Configuration</h4>

                                  <json-viewer
                                      v-if="merge.config.common"
                                      class="my-2 font11"
                                      :value="merge.config.common"
                                      :expand-depth="2"
                                      theme="my-awesome-json-theme"
                                      boxed
                                      height="400px"
                                      expanded
                                  ></json-viewer>
                                  <div v-else>
                                    <div class="text-muted mt-15 pl-3 pr-2">
                                      <p class="pt-5">
                                        <i>
                                          Click preview to see the aggregated configuration
                                        </i>
                                      </p>
                                    </div>

                                  </div>
                                </v-col>
                              </v-row>

                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>

                      <v-col :cols="(isTenant) ? 6 : 12" v-for="(section, i) in tenant.config.specific" :key="i">
                        <v-expansion-panels focusable>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <template v-slot:actions>
                                <v-icon color="success">
                                  mdi-cog
                                </v-icon>
                              </template>
                              <h3>Service: {{ i }}</h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row v-if="!isTenant">
                                <v-col class="text-right" cols="12">

                                  <v-btn
                                      class="mt-2 mr-2" small
                                      color="success"
                                      @click="preview(i)"
                                  >
                                    <v-icon class="mr-1">mdi-eye-refresh-outline</v-icon>
                                    Preview
                                  </v-btn>

                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col :cols="(isTenant) ? 12 : 4">
                                  <h4 v-if="!isTenant">
                                    {{ col1stTitle }}
                                  </h4>
                                  <v-jsoneditor
                                      class="py-2"
                                      v-model="tenant.config.specific[i]"
                                      :plus="true" :options="{mode: 'code'}"
                                      height="400px"
                                  />
                                </v-col>

                                <v-col cols="4" v-if="!isTenant">
                                  <h4>
                                    {{ col2ndTitle }}
                                  </h4>

                                  <json-viewer
                                      v-if="originConf.config && originConf.config.specific[i]"
                                      class="my-2 font11"
                                      :value="originConf.config.specific[i]"
                                      :expand-depth="2"
                                      theme="my-awesome-json-theme"
                                      boxed
                                      expanded
                                      height="400px"
                                      :copyable="true"
                                  ></json-viewer>

                                </v-col>

                                <v-col cols="4" v-if="!isTenant">
                                  <h4> Aggregated Configuration</h4>

                                  <json-viewer
                                      v-if="merge.config.specific[i]"
                                      class="my-2 font11"
                                      :value="merge.config.specific[i]"
                                      :expand-depth="2"
                                      theme="my-awesome-json-theme"
                                      boxed
                                      expanded
                                      height="400px"
                                  ></json-viewer>
                                  <div v-else>
                                    <div class="text-muted mt-15 pl-3 pr-2">
                                      <p class="pt-5">
                                        <i>
                                          Click preview to see the aggregated configuration
                                        </i>
                                      </p>
                                    </div>

                                  </div>
                                </v-col>

                              </v-row>

                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>

                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs>
              </template>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                  class="mr-2"
                  color="success"
                  @click="updateTenantConfig"
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Update
              </v-btn>
              <v-btn
                  class="mr-0"
                  color="error"
                  @click="reset"
              >
                <v-icon class="mr-1">mdi-undo</v-icon>
                Reset
              </v-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import globalMixins from "@/mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";
import VJsoneditor from 'v-jsoneditor';
import JsonViewer from 'vue-json-viewer';

export default {

  name: 'editTenantConfig',

  mixins: [globalMixins, fieldsMixins],

  props: {
    id: {
      type: String
    },
    code: {
      type: String
    },
    user: {
      type: String
    },
    account: {
      type: String
    },
    envSelected: {
      type: Object
    }
  },

  data() {
    return {
      fieldAccess: {},
      isTenant: true,
      tenant: null,
      services: [],
      userRecord: {},
      accountRecord: {},
      originConf: {
        config: {
          specific: {}
        }
      },
      merge: {
        config: {
          specific: {}
        }
      }
    }
  },

  computed: {
    baseMaterialCardTitle() {
      if (this.tenant) {
        if (this.tenant.login) {
          return `Editing General Information of Tenant '${this.tenant.name}'`;
        } else {
          if (this.user) {
            return `Editing Configuration of Tenant '${this.tenant.name}' for user '${this.userRecord.username}'`;
          } else if (this.account) {
            return `Editing Configuration of Tenant '${this.tenant.name}' for organization '${this.accountRecord.name}'`;
          }
        }
        return `Editing Tenant '${this.tenant.name}'`;
      }
      return 'Editing Tenant ';
    },

    col1stTitle() {
      if (this.tenant) {

        if (this.tenant.login) {
          return ``;
        } else {
          if (this.user) {
            return `User Configuration`;
          } else if (this.account) {
            return `Account Configuration`;
          }

        }
        return ``;
      }
      return '';
    },
    col2ndTitle() {
      if (this.tenant) {

        if (this.tenant.login) {
          return ``;
        } else {
          if (this.user) {
            return `Account Configuration`;
          } else if (this.account) {
            return `Tenant Configuration`;
          }
        }
        return ``;
      }
      return '';
    },

    backRoute() {
      if (this.user) {
        return {name: 'editUser', params: {envCode: this.envSelected.value, id: this.user}};
      } else if (this.account) {
        return {name: 'editAccount', params: {envCode: this.envSelected.value, id: this.account}};
      } else {
        return {name: 'Multi Tenancy', params: {envCode: this.envSelected.value}};
      }
    }
  },

  components: {
    VJsoneditor, JsonViewer
  },

  async created() {
    await this.reset();
  },

  methods: {
    async reset() {
      this.clearMessages();
      await this.getServices();

      const tenant = await this.getSendData({
        url: `/consoleapi/environments/${this.envSelected.value}/tenants/${this.id}`,
        method: "get"
      });

      this.tenant = tenant.item;
      if (!this.tenant.config.common) {
        this.tenant.config.common = {};
      }
      if (!this.tenant.config.specific) {
        this.tenant.config.specific = {};
      }
      this.services.items.forEach((service) => {
        if (!this.tenant.config.specific[service.name]) {
          this.tenant.config.specific[service.name] = {};
        }
      });

      if (this.user) {
        this.isTenant = false;
        // get user record
        this.getUser();
      } else if (this.account) {
        // updating account config. compare with tenant config
        this.isTenant = false;
        this.originConf.config = this._lodash.cloneDeep(this.tenant.config);
        // get account record
        this.getAccount();
      } else {
        this.fieldAccess = this.tenantUpdateAccess();
      }
    },

    getAccount() {
      const _self = this;
      const apiOptions = {
        'url': `/consoleapi/organization/accounts/${this.account}`,
        'method': 'get'
      };
      this.getSendData(apiOptions)
          .then((response) => {
            _self.accountRecord = response.item;
            if (!this.accountRecord.config) {
              this.accountRecord.config = {};
            }
            if (this.accountRecord.config[this.tenant.code]) {
              this.tenant.config = this._lodash.cloneDeep(this.accountRecord.config[this.tenant.code]);
            } else {
              this.tenant.config = {};
            }
            if (!this.tenant.config.common) {
              this.tenant.config.common = {};
            }
            if (!this.tenant.config.specific) {
              this.tenant.config.specific = {};
            }
            this.services.items.forEach((service) => {
              if (!this.tenant.config.specific[service.name]) {
                this.tenant.config.specific[service.name] = {};
              }
            });

          });
    },

    getAccountOrg() {
      const _self = this;
      const apiOptions = {
        'url': `/consoleapi/organization/accounts/${this.userRecord.account}`,
        'method': 'get'
      };
      this.getSendData(apiOptions)
          .then((response) => {
            _self.accountRecord = response.item;
            if (!this.accountRecord.config) {
              this.accountRecord.config = {};
            }
            if (this.accountRecord.config[this.tenant.code]) {
              this.originConf.config = this._lodash.cloneDeep(this.accountRecord.config[this.tenant.code]);
            }
            if (!this.originConf.config.specific) {
              this.originConf.config.specific = {};
            }
            this.services.items.forEach((service) => {
              if (!this.originConf.config.specific[service.name]) {
                this.originConf.config.specific[service.name] = {};
              }
            });

            this.$nextTick(() => {
              this.$forceUpdate();
            });

          });
    },

    getUser() {
      const _self = this;
      const apiOptions = {
        'url': `/consoleapi/organization/users/${this.user}`,
        'method': 'get'
      };
      this.getSendData(apiOptions)
          .then((response) => {
            _self.userRecord = response.item;
            this.getAccountOrg();

            if (!this.userRecord.config) {
              this.userRecord.config = {};
            }
            this.tenant.config = {};
            if (this.userRecord.config[this.tenant.code]) {
              this.tenant.config = this._lodash.cloneDeep(this.userRecord.config[this.tenant.code]);
            }
            if (!this.tenant.config.specific) {
              this.tenant.config.specific = {};
            }
            this.services.items.forEach((service) => {
              if (!this.tenant.config.specific[service.name]) {
                this.tenant.config.specific[service.name] = {};
              }
            });

          });
    },

    async getServices() {
      const apiOptions = {
        url: `/consoleapi/environments/${this.envSelected.value}/services`,
        method: "get"
      };
      this.services = await this.getSendData(apiOptions);
    },

    checkBasic(tenant) {
      this.clearMessages();
      if (!tenant.name || tenant.name.trim() === '') {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Field Name is required!'
        });
        return false;
      }

      if (!tenant._TTL) {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Field Caching TTL is required!'
        });
        return false;
      }

      return true;
    },

    updateTenantConfig() {
      let payload = this._lodash.cloneDeep(this.tenant);
      let newConfig = this._lodash.cloneDeep(this.tenant.config);
      let url = `/consoleapi/`;

      for (let key in newConfig.specific) {
        if (Object.keys(newConfig.specific[key]).length === 0) {
          delete newConfig.specific[key];
        }
      }

      if (this.user) {
        // updating user config
        this.userRecord.config[payload.code] = newConfig;
        url += `organization/users/${this.user}/config`;
        payload = {
          code: payload.code,
          config: newConfig
        };

      } else if (this.account) {
        // updating account config
        this.accountRecord.config[payload.code] = newConfig;
        url += `organization/accounts/${this.account}/config`;
        payload = {
          code: payload.code,
          config: newConfig
        };
      } else {

        //updating tenant
        url += `environments/${this.envSelected.value}/tenants/${this.id}`;
        if (payload.login) {
          // 3rd party login tenants
          payload = {
            name: payload.name,
            description: payload.description,
            _TTL: payload._TTL
          };

          if (!this.checkBasic(payload)) {
            return false;
          }
        } else {
          payload = {
            name: payload.name,
            description: payload.description,
            // config: newConfig,
            _TTL: payload._TTL
          };

          if (this.fieldAccess.config) {
            payload.config = newConfig;
          }

          if (!this.checkBasic(payload)) {
            return false;
          }
        }
      }

      let apiOptions = {
        url: url,
        method: "patch",
        params: payload
      };

      this.getSendData(apiOptions).then(() => {
        this.pushMessage({
          type: 'success',
          title: `Tenant Configuration Updated`,
          text: `The configuration of tenant ${this.tenant.name} has been updated!`
        });
        this.scrollToTop();
      });
    },

    preview(service) {
      let first;
      const self = this;
      if (service === 'common') {
        self.merge.config.common = null;
        self.$forceUpdate();
        first = self._lodash.cloneDeep(self.originConf.config.common);

        setTimeout(() => {
          self.merge.config.common = self._lodash.merge(first, self.tenant.config.common);
          self.$nextTick(() => {
            self.$forceUpdate();
          });
        }, 500);

      } else {
        this.merge.config.specific[service] = null;
        this.$forceUpdate();
        first = this._lodash.cloneDeep(this.originConf.config.specific[service]);

        setTimeout(() => {
          self.merge.config.specific[service] = self._lodash.merge(first, self.tenant.config.specific[service]);
          self.$nextTick(() => {
            self.$forceUpdate();
          });
        }, 500);

      }

    }
  }
};
</script>

<style>
.font11 {
  font-size: 11px;
}

.jv-container .jv-code.boxed {
  height: 380px
}
</style>